<template>
  <div>
    <GeneralTable ref="GeneralTable"
      :column="column"
      :option="option"
      :dataList="dataList"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport"
      :totalNum="totalNum">
      <template slot="confirmationUserAvatar"
        slot-scope="{ item }">
        <el-image style="width: 50px; height: 50px"
          :src="item.confirmationUserAvatar"
          :preview-src-list="[item.confirmationUserAvatar]"
          fit="cover"></el-image>
      </template>
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="handleShowDialog(item)">投保确认信息</el-button>
        <el-button type="text"
          @click="handleDel([item])">删除</el-button>
      </template>
    </GeneralTable>
    <info-dialog v-model="showDialog"
      :info="curInfo" />
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import infoDialog from "./components/infoDialog.vue";
import {
  getConfirmPage,
  exportInsConfirmation,
  ConfirmDel,
} from "@/api/policy.js";
import dayjs from "dayjs";

export default {
  components: {
    GeneralTable,
    infoDialog,
  },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      oldData: {},
      showDialog: false,
      curInfo: {},
      option: {
        //   isEdit: true, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isExport: true,
        pageSize: 10,
        searchList: [
          //  {
          //    label: "用户昵称",
          //    prop: "confirmationUserName",
          //    formType: "input",
          //    maxLength: 36,
          //    clearable: true,
          //    placeholder: "请输入",
          //  },
          //  {
          //    label: "手机号",
          //    prop: "confirmationUserPhone",
          //    formType: "input",
          //    maxLength: 36,
          //    clearable: true,
          //    placeholder: "请输入",
          //  },
          {
            label: "投保人",
            prop: "policyHolder",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "证件号",
            prop: "confirmationUserCardNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "询价单号",
            prop: "enquiryNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "业务员",
            prop: "salesmanName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "所属部门",
            prop: "salesmanDeptName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "确认时间",
            // formType: "daterange",
            prop: ["confirmationTimeStart", "confirmationTimeEnd"],
            formType: "daterange",
            clearable: true,
            format: "yyyy.MM.dd",
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "询价单号",
          prop: "enquiryNo",
          isShow: true,
        },
        {
          label: "投保人",
          prop: "policyHolder",
          isShow: true,
        },
        {
          label: "类型",
          prop: "confirmationUserCardTypeDesc",
          isShow: true,
        },
        {
          label: "证件号",
          prop: "confirmationUserCardNo",
          isShow: true,
        },
        //   {
        //     label: "用户昵称",
        //     prop: "confirmationUserName",
        //     isShow: true,
        //   },
        //   {
        //     label: "手机号",
        //     prop: "confirmationUserPhone",
        //     isShow: true,
        //   },
        //   {
        //     label: "用户名",
        //     prop: "confirmationUserRealName",
        //     isShow: true,
        //   },
        //   {
        //     label: "头像",
        //     prop: "confirmationUserAvatar",
        //     isShow: true,
        //     isSlot: true,
        //   },
        {
          label: "客户经理",
          prop: "salesmanName",
          isShow: true,
        },
        {
          label: "所属部门",
          prop: "salesmanDeptName",
          isShow: true,
        },
        {
          label: "确认时间",
          prop: "confirmationTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
    };
  },
  methods: {
    handleShowDialog(item) {
      this.showDialog = true;
      this.curInfo = item;
    },
    handleDel(data) {
      console.log(data, "xxx");
      if (data && data.length) {
        this.$confirm("删除后数据不能恢复是否确认要删除？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let temp = {
              ids: [],
            };
            data.forEach((element) => {
              temp.ids.push(element.id);
            });
            temp.ids = temp.ids.join(",");
            ConfirmDel(temp).then((res) => {
              if (res) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.$refs.GeneralTable.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要删除的数据！");
      }
    },
    handleExport(data) {
      // if (!data || !data.length) {
      //   this.$message.warning("请选择需要导出的托管单！");
      //   return false;
      // }
      let ids = data.map((val) => val.id).toString();
      let dataObj = {};
      if (ids) {
        dataObj.ids = ids;
      } else {
        dataObj = JSON.parse(JSON.stringify(this.oldData));
        delete dataObj.current;
        delete dataObj.pageSize;
        delete dataObj.pageNum;
        delete dataObj.size;
      }
      exportInsConfirmation(dataObj).then((res) => {
        if (res) {
          this.$message({
            message: "导出成功",
            type: "success",
          });
        }
      });
    },
    handleSearch(data, pageNum, pageSize, searcType, isReset) {
      data.current = data.pageNum;
      data.size = data.pageSize;
      if (data.confirmationTimeStart) {
        data.confirmationTimeStart =
          dayjs(data.confirmationTimeStart).format("YYYY-MM-DD") + " 00:00:00";
        data.confirmationTimeEnd =
          dayjs(data.confirmationTimeEnd).format("YYYY-MM-DD") + " 23:59:59";
      }
      this.oldData = JSON.parse(JSON.stringify(data));
      this.getList(data, { current: data.pageNum, size: data.pageSize });
    },
    getList(data, params) {
      getConfirmPage({ ...data }, params)
        .then((res) => {
          this.dataList = res.data.records;
          this.totalNum = res.data.total;
        })
        .catch((err) => {
          this.dataList = [];
          this.totalNum = 0;
        });
    },
  },
};
</script>

<style></style>
