<template>
  <el-dialog title="投保确认信息"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    :close-on-click-modal="false"
    width="850px"
    @close="dialogClose"
    :before-close="handleClose">
    <div class="content">
      <div class="wrap">
        <div class="title">身份认证信息</div>
        <div class="info-wrap">
          <el-image style="width: 100px; height: 100px"
            class="avatar"
            :src="info.photo"
            fit="cover"></el-image>
          <div class="info">
            <div>姓名：{{ info.policyHolder }}</div>
            <div>身份证号：{{ info.confirmationUserCardNo }}</div>
            <div>认证时间：{{ info.confirmationTime }}</div>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="title">电子签名</div>
        <div class="info">
          <div>
            投保人签名：
            <el-image style="height: 50px"
              class="avatar"
              :src="info.signUrl"
              fit="cover"></el-image>
          </div>
          <div>签名时间：{{ info.confirmationTime }}</div>
        </div>
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button type="primary"
        @click="dialogVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    showDialog: Boolean,
    info: Object,
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() { },
  computed: {},
  methods: {
    dialogClose() { },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .wrap {
    width: 395px;
    height: 215px;
    padding: 24px;
    background-color: #f6f8f9;
    border-radius: 4px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      margin-bottom: 24px;
    }
    .info-wrap {
      display: flex;
      .avatar {
        margin-right: 24px;
      }
    }
    .info {
      > div {
        margin-bottom: 24px;
      }
    }
  }
}
::v-deep .policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    overflow: auto;
    padding: 24px;
  }
}
</style>
